import React, { Component } from "react";

import Radio, { RadioGroup, RadioCard } from "@invisionag/iris-react-radio";
import mapboxgl from "mapbox-gl";

import NeatRTC from "neat-rtc";
import MenuApp from './Menu';
var data = {
  x: 100,
  array: [
    {
      Title: "Avez-vous des difficultés à respirer ?",

      ok: 1,
      perc: 40,

      urgent: 1,
      response: [{ label: "Oui", value: 1 }, { label: "Non", value: 2 }]
    },
    {
      Title: "Est-ce que vous avez une toux récente ?",

      Message:"Toux récente signifie une toux que vous n'aviez pas avant ou si vous toussez de manière chronique, que votre toux s'est empirée.",

      ok: 1,
      perc: 40,

      urgent: 1,
      response: [{ label: "Oui", value: 1 }, { label: "Non", value: 2 }]
    }
    ,


    {
      Title: "Avez-vous mal à la gorge ?",

      ok: 1,
      perc: 20,

      urgent: 0,
      response: [{ label: "Oui", value: 1 }, { label: "Non", value: 2 }]
    },


        {
          Title: "Avez-vous une autre maladie connue ?",

          ok: 1,
          perc: 10,

          urgent: 0,
          response: [{ label: "Oui", value: 1 }, { label: "Non", value: 2 }]
        },




    {
      Title: "Avez-vous une sensation de fièvre ?",
      Message: "Par exemple des frissons ou une sensation de chaleur",

      perc: 3,
      urgent: 0,
      ok: 1,
      response: [{ label: "Oui", value: 1 }, { label: "Non", value: 2 }]
    },

    {
      Title: "Avez-vous de la fièvre ?",

      perc: 12,
      urgent: 0,
      ok: 1,
      response: [{ label: "Oui", value: 1 }, { label: "Non", value: 2 }]
    },


    {
      Title: "Avez-vous une impossibilité de manger ou boire depuis 24 heures ou plus ?",

      ok: 1,
      perc: 90,

      urgent: 1,
      response: [{ label: "Oui", value: 1 }, { label: "Non", value: 2 }]
    }





  ]
};

class App extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      step: false,
      level: 0,
      value: 0,
      share:false,
      quiz: 1,
      myrespone: [],
      data: data,
      stepIndex: 0
    };
  }

  componentDidMount() {

    if (navigator.share) {

  this.setState({ share:true });
    }


  }

  onChange = e => {
  //  console.log("radio checked", e);
    this.setState({
      value: e
    });
  };

  go = data => {
    let myrespone = this.state.myrespone;
    data["myrespone"] = this.state.value;
    var result = 0;
    var stepindex = this.state.stepIndex + 2;
    var length = this.state.data.array.length;

    myrespone.push(data);

    this.setState({ myrespone });

    if (this.state.stepIndex == length) {
    //  console.log(result);
      for (var i = 0; i < myrespone.length; i++) {
    //    console.log(result);
        if (myrespone[i].ok == myrespone[i].myrespone) {
    ///      console.log(result, myrespone[i].perc);

          result = result + myrespone[i].perc;
        }
      }
    } else {
      this.setState({ stepIndex: this.state.stepIndex + 1, value: 0 });
    }

    if (stepindex > length) {
      this.setState({ quiz: 3 });

      console.log(myrespone);
      var perc = 0;
      var urgent = 0;
      for (var i = 0; i < myrespone.length; i++) {
        if (myrespone[i].myrespone == myrespone[i].ok) {
          perc = myrespone[i].perc + perc;
          urgent = myrespone[i].urgent + urgent;
        }
      }

      //console.log(urgent, perc);
      if (this.state.data.x < perc) {
        if (urgent > 0) {
          this.setState({ level: 3 });
        }
      } else if (this.state.data.x >= perc) {
        if (urgent > 0) {
          this.setState({ level: 2 });
        }
      } else if (perc == 0) {
        if (urgent == 0) {
          this.setState({ level: 1 });
        }
        }else if (perc == 0 &&  urgent == 1) {
          if (urgent == 0) {
            this.setState({ level: 1 });
          }
      }

    }
    //console.log("Level ", this.state.level);
  };


  share= data => {

    if (navigator.share) {
      navigator.share({
        title: 'Testez vos symptômes de Coronavirus COVID-19<',
        text: "Vous pensez avoir des symptômes liés au coronavirus COVID-19 ? Test en ligne, gratuit et anonyme",
        url: 'https://covid19.genielabel.com/test',
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    }else {
      //console.log("Web Share");

    }

  }
  render() {
    return (
      <div className="App">
          <MenuApp {...this.props}  />
        <div class="   ">
          <div class="container">
            <div class="section text-center">
              {this.state.quiz == 1 && (
                <div class="row">
                  <div class="col-md-8 ml-auto mr-auto">
                    <h2 class="title">
                      Vous avez des doutes sur votre état de santé ?
                    </h2>
                    <h5 class="description" style={{ color: "#222" ,fontFamily: 'Baloo',fontSize: 18}}>
                      Les ressources proposées sont adaptées aux personnes
                      résidant en RDC dans les partie infecté. Elles sont mises à jour régulièrement
                      selon les recommandations du ministère de la santé.
                    </h5>

                    <div align="center">
                      <button
                        class="btn btn-primary btn-round"
                        class="btn btn-primary btn-round"
                        style={{fontSize:19}}
                        onClick={() => this.setState({ quiz: 2 })}
                      >
                        Commancer
                      </button>
                    </div>
                  </div>
                </div>
              )}
              <div class="features">
                {this.state.quiz == 2 && (
                  <div align="center">
                    {this.state.data.array && (
                      <div class="col-md-8 ml-auto mr-auto">
                        <h2 class="title">
                          {this.state.data.array[this.state.stepIndex].Title}
                        </h2>
                        <h5 class="description">
                          {this.state.data.array[this.state.stepIndex].Message}
                        </h5>

                        <div style={{ marginLeft: "35%" }}>
                          <RadioGroup
                            name="basic-group"
                            selectedValue={this.state.value}
                          >
                            {this.state.data.array[this.state.stepIndex] &&
                              this.state.data.array[
                                this.state.stepIndex
                              ].response.map((response, i) => (
                                <RadioCard
                                  value={response.value}
                                  onChange={() => this.onChange(response.value)}
                                >
                                  {response.label}
                                </RadioCard>
                              ))}
                          </RadioGroup>
                        </div>

                        <div style={{ marginTop: 100 }}>
                          {this.state.value == 1 && (
                            <button
                              class="btn btn-primary btn-round"
                              class="btn btn-primary btn-round"
                              style={{fontSize:19}}
                              onClick={() =>
                                this.go(
                                  this.state.data.array[this.state.stepIndex]
                                )
                              }
                            >
                              Continuer
                            </button>
                          )}

                          {this.state.value == 2 && (
                            <button
                              class="btn btn-primary btn-round"
                              style={{fontSize:19}}
                              onClick={() =>
                                this.go(
                                  this.state.data.array[this.state.stepIndex]
                                )
                              }
                            >
                              Continuer
                            </button>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>

              {this.state.quiz == 3 && (
                <div class="row">
                  <div class="col-md-8 ml-auto mr-auto">
                    <div style={{ marginTop: 100 }}>
                      {this.state.level == 0 && (
                        <div>
                        <h2 class="title">
                          Vous ne présentez pas les symptômes du cas possible d’infection Covid-19.
                        </h2>
                          <h5 class="description">
                      </h5>
                        </div>
                      )}

                      {this.state.level == 2 && (
                        <div>
                        <h2  >
                          Vous décrivez des symptômes compatibles avec une
                          infection respiratoire, vous décrivez également de la
                          fièvre ou une sensation de fièvre
                        </h2>

                        </div>
                      )}

                      {this.state.level == 3 && (
                        <div>
                        <h2 class="title">Vous décrivez des symptômes compatibles avec un syndrome de détresse respiratoire aiguë devant faire l’objet prise en charge médicale en urgence </h2>
                        <h5 class="description" style={{fontFamily: "Baloo"}}>
                          Appelez le 109 ou le 110 immédiatement afin de bénéficier de la prise en charge la plus rapide et adaptée possible.
                        </h5>
                        </div>
                      )}


                      {this.state.share &&
                       <button
                         class="btn btn-dark btn-round"
                         onClick={() => this.share(data)}
                       >        <i class="material-icons">share</i>
                         Partager
                       </button>
                     }
                      <h5 class="description" style={{ color: "#222" ,fontFamily: 'Baloo'}}>
                        Ce message ne qualifie en aucun cas un diagnostic
                        individuel ou une prescription médicale et ne remplace
                        en aucun cas une prise en charge médicale par un
                        professionnel de santé compétent. Ce message repose sur
                        les recommandations du ministère de la santé.
                      </h5>

                      <div align="center"></div>
                    </div>
                  </div>
                </div>
              )}
            </div>{" "}
          </div>
        </div>{" "}
      </div>
    );
  }
}

export default App;
