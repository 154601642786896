import React, { Component } from "react";
import {

  Link,

} from "react-router-dom";
const mediasoup = require('mediasoup-client');
const socketClient = require('socket.io-client');
const socketPromise = require('./lib/socket.io-promise').promise;
const config = require('./config');
const hostname = window.location.hostname;
    const serverUrl = `http://${hostname}:${config.listenPort}`;
    const opts = {
      path: '/server',
      transports: ['websocket'],
    };
let socket;
    socket = socketClient(serverUrl, opts);
    socket.request = socketPromise(socket);

class App extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      step: false,
      level: 0,
      value: 0,
      producer:null,
      stream:null,
      device:null,
      socket:socket,
      quiz: 1,
      myrespone: [],

      stepIndex: 0
    };
  }

  componentDidMount() {
  let device;


  const hostname = window.location.hostname;


  if (typeof navigator.mediaDevices.getDisplayMedia === 'undefined') {
alert("Not supported")
}else {
  console.log("All is good")
}


  };


  async connect () {





      console.log("Connected");
      /*
      $txtConnection.innerHTML = 'Connected';
      $fsPublish.disabled = false;
      $fsSubscribe.disabled = false;

      this.setState({
        value: e
      });
*/
      const data = await this.state.socket.request('getRouterRtpCapabilities');

      await this.loadDevice(data);


    this.state.socket.on('disconnect', () => {
      /*
      $txtConnection.innerHTML = 'Disconnected';
      $btnConnect.disabled = false;
      $fsPublish.disabled = true;
      $fsSubscribe.disabled = true;
      */
    });

    this.state.socket.on('connect_error', (error) => {
      console.error('could not connect to %s%s (%s)', serverUrl, opts.path, error.message);
      /*
      $txtConnection.innerHTML = 'Connection failed';
      $btnConnect.disabled = false;
      */
    });

    this.state.socket.on('newProducer', () => {
  console.log("newProducer");
    });
  }



  async getUserMedia(transport, isWebcam) {
    if (!this.state.device.canProduce('video')) {
      console.error('cannot produce video');
      return;
    }

    let stream;
    try {
      stream = isWebcam ?
        await navigator.mediaDevices.getUserMedia({ video: true }) :
        await navigator.mediaDevices.getDisplayMedia({ video: true });
    } catch (err) {
      console.error('getUserMedia() failed:', err.message);
      throw err;
    }
    return stream;
  }


   async loadDevice(routerRtpCapabilities) {
   var mm;

   try {
var mm = new mediasoup.Device();

   } catch (error) {
     if (error.name === 'UnsupportedError') {
       console.error('browser not supported');
     }
   }

   this.setState({
     device: mm
   });
   await mm.load({ routerRtpCapabilities });
 }






 async   publish() {
   const isWebcam = true;

console.log("Gooddd...");
   const data = await socket.request('createProducerTransport', {
     forceTcp: false,
     rtpCapabilities: this.state.device.rtpCapabilities,
   });
   if (data.error) {
     console.error(data.error);
     return;
   }

   const transport = this.state.device.createSendTransport(data);
   transport.on('connect', async ({ dtlsParameters }, callback, errback) => {
     socket.request('connectProducerTransport', { dtlsParameters })
       .then(callback)
       .catch(errback);
   });

   transport.on('produce', async ({ kind, rtpParameters }, callback, errback) => {
     try {
       const { id } = await socket.request('produce', {
         transportId: transport.id,
         kind,
         rtpParameters,
       });
       callback({ id });
     } catch (err) {
       errback(err);
     }
   });

   transport.on('connectionstatechange', (state) => {
     switch (state) {
       case 'connecting':
     console.log("State : ", state);
       break;

       case 'connected':

         this.remote_video.srcObject =   stream;
     console.log(stream);
       break;

       case 'failed':
         transport.close();


       break;

       default: break;
     }
   });

   let stream;
   try {
     stream = await this.getUserMedia(transport, isWebcam);
     const track = stream.getVideoTracks()[0];
     const params = { track };
     if (false) {
       params.encodings = [
         { maxBitrate: 100000 },
         { maxBitrate: 300000 },
         { maxBitrate: 900000 },
       ];
       params.codecOptions = {
         videoGoogleStartBitrate : 1000
       };
     }
     let producer = await transport.produce(params);

     this.setState({
       producer: producer
     });
   } catch (err) {
console.log("failed");
   }
 }





 async  subscribe() {
   const data = await socket.request('createConsumerTransport', {
     forceTcp: false,
   });
   if (data.error) {
     console.error(data.error);
     return;
   }

   const transport = this.state.device.createRecvTransport(data);
   transport.on('connect', ({ dtlsParameters }, callback, errback) => {
     this.state.socket.request('connectConsumerTransport', {
       transportId: transport.id,
       dtlsParameters
     })
       .then(callback)
       .catch(errback);
   });

   transport.on('connectionstatechange', async (state) => {
     switch (state) {
       case 'connecting':
      console.log("subscribe...");
         break;

       case 'connected':

            this.remote_video.srcObject =  await stream;

            console.log(stream);
         await socket.request('resume');
      console.log("subscribe");
         break;

       case 'failed':
         transport.close();
       console.log("failed");
         break;

       default: break;
     }
   });

   const stream = this.consume(transport);
 }





 async consume(transport) {
   const { rtpCapabilities } = this.state.device;
   const data = await this.state.socket.request('consume', { rtpCapabilities });
   const {
     producerId,
     id,
     kind,
     rtpParameters,
   } = data;

   let codecOptions = {};
   const consumer = await transport.consume({
     id,
     producerId,
     kind,
     rtpParameters,
     codecOptions,
   });
   const stream = new MediaStream();
   stream.addTrack(consumer.track);
   return stream;
 }


  render() {
    return (
      <div>

      <table>
        <tr>
          <td>
            <div>Local</div>
            <video id="local_video" ref={local_video=> {this.local_video = local_video}}   loop   autoPlay  ></video>
          </td>
          <td>
            <div>Remote</div>
            <video id="remote_video" loop   autoPlay  ></video>
          </td>
        </tr>
      </table>
      <br/>
      <table>
        <tr>
          <td>
            <fieldset id="fs_connection">
              <legend>Connection</legend>
              <div><button id="btn_connect"  onClick={() => this.publish()}>Publish</button> <span id="connection_status"></span></div>

              <div><button id="btn_connect"  onClick={() => this.connect()}>Connect</button> <span id="connection_status"></span></div>
            </fieldset>
          </td>
          <td>
            <fieldset id="fs_publish" disabled>
              <legend>Publishing</legend>
              <div><label><input type="checkbox" id="chk_simulcast" /> Use Simulcast</label></div>
              <div>
                <button id="btn_webcam"  onClick={() => this.publish()}>Publish</button>
                <span id="webcam_status"></span>
              </div>
              <div>
                <button id="btn_screen">Share Screen</button>
                <span id="screen_status"></span>
              </div>
            </fieldset>
          </td>
          <td>
            <fieldset id="fs_subscribe" disabled>
              <legend>Subscription</legend>
              <div><button id="btn_subscribe"     >Subscribe</button> <span id="sub_status"></span></div>
            </fieldset>
          </td>
        </tr>
      </table>
      </div>

    );
  }
}

export default App;
