import React, { Component } from "react";

import Radio, { RadioGroup, RadioCard } from "@invisionag/iris-react-radio";
import mapboxgl from "mapbox-gl";
import MenuApp from './Menu';
import NeatRTC from "neat-rtc";



class App extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      step: false,
      level: 0,
      value: 0,
      quiz: 1,
      myrespone: [],

      stepIndex: 0
    };
  }

  componentDidMount() {}

  onChange = e => {
    console.log("radio checked", e);
    this.setState({
      value: e
    });
  };


  render() {
    return (
      <div className="App">
          <MenuApp {...this.props}  />
        <div class="  ">
          <div class="contafiner">
            <div class="section text-center">
              {this.state.quiz == 1 && (
                <div class="row">
                  <div class="col-md-8 ml-auto mr-auto">
                    <h2 class="title">
                  Qui sommes-nous ?
                    </h2>
                    <h5 class="description" style={{fontFamily: "Baloo",fontSize: 18}}>
Nous sommes une équipe de jeunes informaticiens congolais, nous avons fait le pari de développer ce site Web totalement gratuit et independant   pour enfin permettre aux gens de savoir l'état du coronavirus dans leurs communes et autres.
                    </h5>


                  </div>
                </div>
              )}



              {this.state.quiz == 1 && (
                <div class="row">
                  <div class="col-md-8 ml-auto mr-auto">


                  </div>
                </div>
              )}
     <br/>     <br/>     <br/>     <br/>

 <div class="main main-raised">
   <div class="container">
     <div class="sectiodn text-center">
       <div class="row">
         <div class="col-md-8 ml-auto mr-auto">
           <h2 class="title" style={{fontFamily: "Baloo",fontSize:25}}>Vous souhaitez nous aider à combattre ce virus ?</h2>
           <h5 class="description" style={{fontFamily: "Baloo",fontSize: 20}}>Vous pouvez nous aider de 3 manières suivant votre profil</h5>
<img src="iconss.png" width="150" />
            </div>
       </div>

       <div class="features">
         <div class="rodw">
           <div class="col-mdd-6">
             <div class="info">

               <h4 class="info-title" style={{fontFamily: "Baloo",fontSize: 18}}>Vous souhaitez participer au developpement de Sango Covid19 App ?</h4>
<br/>   <h4 class="info-title" style={{fontFamily: "Baloo",fontSize: 18}}>Vous etes un data scientifique ou un developpeur ?</h4>
               </div>

           </div>

           <div class="">
             <div class="info">


                </div>

             <a
               class="btn btn-primary btn-round"
            href="mailto:sango@genielabel.com
?subject=participer au developpement de Sango Covid19 App
&body=Présentez vous svp..."
             >
               contactez-nous
             </a>
           </div>
         </div>
         <div align="center">
           <div class="info" style={{fontFamily: "Baloo",fontSize: 18}}>



                </div>


                <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
    <input type="hidden" name="cmd" value="_donations" />
    <input type="hidden" name="business" value="hello@genielabel.com" />
    <input type="hidden" name="item_name" value="Faire un don à  Sango Covid19 " />
    <input type="hidden" name="currency_code" value="EUR" />
    <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
    <img alt="" border="0" src="https://www.paypal.com/en_CD/i/scr/pixel.gif" width="1" height="1" />
    </form>


         </div>
         <br/><br/>
       </div>
     </div>



              <div class="features">
                {this.state.quiz == 2 && (
                  <div align="center">
                    {this.state.data.array && (
                      <div class="col-md-8 ml-auto mr-auto">
                        <h2 class="title">
                          {this.state.data.array[this.state.stepIndex].Title}
                        </h2>
                        <h5 class="description">
                          {this.state.data.array[this.state.stepIndex].Message}
                        </h5>

                        <div style={{ marginLeft: "35%" }}>
                          <RadioGroup
                            name="basic-group"
                            selectedValue={this.state.value}
                          >
                            {this.state.data.array[this.state.stepIndex] &&
                              this.state.data.array[
                                this.state.stepIndex
                              ].response.map((response, i) => (
                                <RadioCard
                                  value={response.value}
                                  onChange={() => this.onChange(response.value)}
                                >
                                  {response.label}
                                </RadioCard>
                              ))}
                          </RadioGroup>
                        </div>

                        <div style={{ marginTop: 100 }}>
                          {this.state.value == 1 && (
                            <button
                              class="btn btn-primary btn-round"
                              onClick={() =>
                                this.go(
                                  this.state.data.array[this.state.stepIndex]
                                )
                              }
                            >
                              Continuer
                            </button>
                          )}

                          {this.state.value == 2 && (
                            <button
                              class="btn btn-primary btn-round"
                              onClick={() =>
                                this.go(
                                  this.state.data.array[this.state.stepIndex]
                                )
                              }
                            >
                              Continuer
                            </button>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>

  </div>  </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
