import React, { Component } from "react";
import { Steps, Button, message } from 'antd';
import MenuApp from './Menu';
const { Step } = Steps;

const steps = [
  {
    title: 'RESTEZ chez vous autant que possible',

  },
  {
    title: 'GARDEZ une distance de sécurité',

  },
  {
    title: 'LAVEZ-VOUS souvent les mains',

  },
  {
    title: 'COUVREZ-VOUS la bouche quand vous toussez',

  }

  ,
  {
    title: 'VOUS ÊTES MALADE? Appelez le 109 ou le 110',

  }
];

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }

  next() {
    const current = this.state.current + 1;
    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  render() {
    const { current } = this.state;
    return (
      <div>
          <MenuApp {...this.props}  />
    <div  className='container' >
    <br/>  <br/>  <br/>  <br/>  <br/>
        <Steps current={current} direction="vertical">
          {steps.map(item => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className="steps-content">{steps[current].content}</div>
        <div className="steps-action">
          {current < steps.length - 1 && (
            <Button type="primary" onClick={() => this.next()}>
            Suivant
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button type="primary" onClick={() => message.success('Voici les gestes barrières pour se protéger et protéger les autres du coronavirus... Merci pour vos gestes 🙏🏾', 10)}>
              Suivant
            </Button>
          )}
          {current > 0 && (
            <Button style={{ margin: 8 }} onClick={() => this.prev()}>
    Prècédent
            </Button>
          )}
        </div>
      </div>
      </div>
    );
  }
}
