import React, { Component } from 'react';


import mapboxgl from 'mapbox-gl';
import axios from 'axios';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import NeatRTC from 'neat-rtc';
import Province from "./Province.json";
import { Button, notification,Tabs, Radio } from 'antd';
import MenuApp from './Menu';
const { TabPane } = Tabs;
const token = 'pk.eyJ1IjoiZ2VuaWVsYW5lbCIsImEiOiJjazhvYTYyYnEwMHhrM2tveWIwcDlma2QxIn0.A-mmxLyBgHLGGQoc067lng';
class App extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {

      provinces: [],
      data:false,
      province:{},
      share:false,
      commune:null,
          communeIndex:null,



    };
    this.map = null;
  }

  openNotification = () => {
  const key = `open${Date.now()}`;
  const btn = (
    <Button type="primary" size="small" onClick={() => notification.close(key)}>
      ok
    </Button>
  );
  notification.open({
    message: 'Notification',
     duration: 10000,
    description:
      'Les informations sur cette carte ne sont plus mises à jour...',
    btn,
    key,

  });
}
componentDidMount() {


this.openNotification()
  if (navigator.share) {

this.setState({ share:true });
  }
  mapboxgl.accessToken = token;

  var hoveredStateId = null;
  var map = new mapboxgl.Map({
  container: this.mapContainer,
  comune:false,
  then:false,
  slide:[],
    slidee:[],
  style: 'mapbox://styles/genielanel/ck8kdlt9o0fig1ip03unzfntn',
  center: [
    23.280370149638973,
    -2.080755871206932
  ],
  zoom: 4
  });


   var pulsingDot =  window["pass_function"](map);





  var array = [];
    var communes = [];

  axios.get(`https://apps.genielabel.com/api/dataset/v1`)
    .then(res => {
      const data = res.data.dataset;

      this.setState({ data:res.data });


for (var i = 0; i < data.length; i++) {
      var myget =  Province.features.find(x => x.properties.nom_prov === data[i].nom_prov);
  var pr =  myget.properties['case'] =  data[i].case;
  array.push(Province.features[i])

      this.setState({ provinces:array});
for (var j = 0; j < data[i].communes.length; j++) {



  communes.push({
  'type': 'Feature',


  'geometry': {
  'type': 'Point',


  'coordinates':data[i].communes[j].position.coordinates,
  }
  } )


}

if (true) {

}


}







if (this.state.provinces.length > 25) {

  var tt = {"type":"FeatureCollection","features":this.state.provinces};


  console.log('Init map**************** Your are a developer contact us hello@genielabel.com ************************ together we can change the word');


    var hoveredStateId = null;








    map.on('load',  () => {

    // Add a source for the state polygons.
    map.addSource('states', {
    'type': 'geojson',
    'data':tt
    });

    map.on('idle', () => {
     this.setState({ then:false });
  //   console.log("Chargger");
   })




    var expression = ['match', ['get', 'OBJECTID']];


    var provinces = [];
          const exprs = ["case"];


      const states = tt.features;

            states.forEach(state => {
              exprs.push(["==", ["get", "case"], state.properties.case]);
              exprs.push(this.colorMap(state.properties.case));
            })


    // Last value is the default, used where there is no data
    exprs.push('rgba(96, 125, 139,0.7)');




    // Add a layer showing the state polygons.
    map.addLayer(
    {
      'id': 'states-layer',
      'type': 'fill',
      'source': 'states',
      'maxzoom': 8,

    'paint': {
      'fill-color':exprs

    }
    },
    'waterway-label'
    );










    map.addLayer(
    {
      'id': 'states-zoom',
      'type': 'fill',
      'source': 'states',
      'minzoom': 6,
      'paint': {
'fill-color': exprs,
'fill-opacity': 0.1,
}

    },

    );






    map.addControl(
    new MapboxGeocoder({
    accessToken: token,
language:"fr",
countries:"cd",

    marker:false,
    flyTo: {
    bearing: 0,
    zoom:12,
    // These options control the flight curve, making it move
    // slowly and zoom out almost completely before starting
    // to pan.

    curve: 3, // change the speed at which it zooms out
    // This can be any easing function: it takes a number between
    // 0 and 1 and returns another number between 0 and 1.
    easing: function(t) {
    return t;
    }
    },
    placeholder: 'Recherche...',

    mapboxgl: mapboxgl
    })
    );

    map.addControl(new mapboxgl.GeolocateControl({
      positionOptions: {
          enableHighAccuracy: true
      },
      trackUserLocation: true,

    }));



/*
    map.on('click', 'points',  (e) => {
     var popup = new mapboxgl.Popup({closeButton:true})
    .setLngLat(e.lngLat)

    .setHTML(`<span class="margin-left: 120px;"> Certains cas  sont détecter dans cette commune
    </div>`)

    .on('open',  (b) => {

    })


    .addTo(map);

    })
*/











    map.on('zoom' , 'states-zoom', (e) => {
    //  console.log("Zoom event : ",e.features[0].properties.nom_prov);

    if (map.getZoom() > 7) {

      this.setState({ commune:e.features[0].properties.nom_prov});
      this.getCommunes();
    } else {

        this.setState({ commune:false});
    }
    });






    // <span class="dot"> <i   style="color:#fff"  class="material-icons">v</i>  </span> When a click event occurs on a feature in the states layer, open a popup at the <span class="badge   badge-info" style="font-size:14px">22 cas confirmé</span> </h3>   <br/><a  href="/test" class="btn btn-default btn-round"><i class="material-icons">visibility</i> Voir dans le commune</a>
    // location of the click, with description HTML from its properties.
    map.on('click', 'states-layer',  (e) => {
            this.setState({ commune:e.features[0].properties.nom_prov});
                  this.getCommunes();
     var popup = new mapboxgl.Popup({closeButton:true})
    .setLngLat(e.lngLat)

    .setHTML(`<span class="margin-left: 120px;"> ${e.features[0].properties.nom_prov} <span><div class="item1" style="margin-top:-10px;"><span style="font-size:15px;"> ${e.features[0].properties.case} </span><span style="color:#222"> Cas  confirmés   </span>
    </div>`)

    .on('open',  (b) => {
/*
      console.log(b);
      map.flyTo({
    center: [b.target._lngLat.lng,b.target._lngLat.lat],
    zoom:5,
    essential: true // this animation is considered essential with respect to prefers-reduced-motion
    });


  */
    //provinces.push({position:[b.target._lngLat.lng,b.target._lngLat.lat],name:e.features[0].properties.nom_prov})
    //console.log("Provinces " , provinces.length);

    //localStorage.setItem('provinces', JSON.stringify(provinces));
    })



    .addTo(map);


    });



    map.addImage('pulsing-dot',  pulsingDot, { pixelRatio: 2 });

    map.addSource('points', {
    'type': 'geojson',
    'data': {
    'type': 'FeatureCollection',


    'features': communes
    }
    });
    map.addLayer({
    'id': 'points',

    'type': 'symbol',
    'source': 'points',

          maxzoom:8,
    'layout': {
    'icon-image': 'pulsing-dot'
    }
    });



    });





}
    this.map = map;
      })






}

getCommunes()

{


        var data =  this.state.data.dataset.find(x => x.nom_prov=== this.state.commune);
var communes = data.communes;
//console.log("Cummunes ", communes);
this.setState({ slidee:communes,province:data});


}
















findByArray(data){



}








go(){

  alert("gooo")
}



share= data => {

  if (navigator.share) {
    navigator.share({
      title: 'Carte du coronavirus',
      text: this.state.province.case + "CAS CONFIRMÉS À " + this.state.province.nom_prov  +   " cliquez sur ce lien pour en savoir plus ",
      url: 'https://covid19.genielabel.com',
    })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
  }else {
  //  console.log("Web Share");

  }

}

getDatabyInd(index){

    var data =  this.state.data.dataset.find(x => x.nom_prov=== index);

if (data) {
  return data
}else {
return  [];
}

}


handleModeChange = index => {
/*
  this.map.flyTo({
center: this.state.slidee[index].coordinates,
zoom:14,

essential: true // this animation is considered essential with respect to prefers-reduced-motion
});

*/


 };


 coordinateFeature(lng, lat) {
return {
center: [lng, lat],
geometry: {
type: 'Point',
coordinates: [lng, lat]
},
place_name: 'Lat: ' + lat + ' Lng: ' + lng,
place_type: ['coordinate'],
properties: {},
type: 'Feature'
};
}




colorMap(data){

  if (data == 0) {
    return "rgba(0, 0, 0,0.1)"

  }else if (data <= 50) {
      return "rgba(244, 67, 54,0.5)";

  }
    else if (data <= 100) {
        return "rgba(244, 67, 54,0.8)";
      }  else if (data <= 300) {
            return "rgba(244, 67, 54,1.0)";

    }



}

  render() {


  const renderOptions = (option, i) => {
    return (
      <label key={i} className="toggle-container">
        <input onChange={() => this.setState({ active: i })} checked={i === 1} name="toggle" type="radio" />
        <div className="toggle txt-s py3 toggle--active-white">{option.name}</div>
      </label>
    );
  }

  var options = [{id:1,name:"Carte"},{id:1,name:"Liste de commune"}]
    return (



      <div>
      <div  style={{paddingTop: 60 }} >

<div  ref={el => this.mapContainer = el} style={{height: "91.5vh"}} className=" right left bottom" />


      <MenuApp {...this.props}  />


{this.state.then &&
<div id="loadingDiv" class="loading"></div>
}
</div>


       <div id="state-legend" className="legend">
{!this.state.commune && this.state.data &&
       <div  style={{backgroundColor: "#fff"}} class='w340 round shadow-darken10 px12 py12 txt-s'>
<h4 style={{fontFamily: "Baloo"}}> {this.state.data.cas} CAS CONFIRMÉS  </h4>
   <strong  style={{fontFamily: "Baloo"}} class='block mb6' style={{color: "#000"}}>{this.state.data.die} Décès et {this.state.data.success} guéries.</strong>
   <div class='grid mb6'>
     <div style={{backgroundColor: "rgba(0, 0, 0,0.1)"}} class='col   h12'></div>
     <div  style={{backgroundColor: "rgba(244, 67, 54,0.5)"}} class='col  '></div>
     <div style={{backgroundColor: "rgba(244, 67, 54,0.7)"}}  class='col  '></div>
     <div style={{backgroundColor: "rgba(244, 67, 54,1.0)"}} class='col  '></div>
   </div>

   <div class='grid txt-xs'>
        <div class='col align-center' style={{color: "#000"}}>0</div>
     <div class='col align-center' style={{color: "#000"}}>-50</div>

     <div class='col align-center' style={{color: "#000"}}>-100</div>
     <div class='col align-center' style={{color: "#000"}}>-300</div>
   </div>





  </div>
}


     </div>





     {this.state.commune &&
     <div className="legend2">
 {this.state.slidee &&

   <div  className='container' >
      <div class="col-md-12">
        {/* <h5 style={{fontFamily:"Baloo" }}><span style={{fontSize: 20,fontFamily:"Baloo" }}>{this.state.province.case}</span>  CAS CONFIRMÉS À {this.state.province.nom_prov}</h5> <br/> */}
                 {this.state.slidee.length > 0 &&    <h6> Liste des cas par regions ou communes  à {this.state.province.nom_prov}   : </h6>}
         {this.state.slidee.length > 0 &&
     <Tabs defaultActiveKey={0}    tabPosition={"top"} style={{ minHeight: 220 ,}} >

   {this.state.slidee.map((data, i) => (
         <TabPane tab={data.Name}    key={i}>
         <div  className='row' >
            <div class="col-md-12">
         <h5 style={{fontFamily:"Baloo" }}><span style={{fontSize: 40,fontFamily:"Baloo" }}>{data.case.length}</span>  CAS {this.state.slidee.length > 1 && <span> CONFIRMÉS </span> }</h5> <br/>
         <div style={{height: 5,width: 100,backgroundColor:"rgba(255, 193, 7,1.0)",borderRadius: 20,marginTop: -30}} />
            {this.state.slidee.length == 1 &&
         <h6> Tout les cas confirmé à {this.state.province.nom_prov}  </h6>
       }

       {this.state.slidee.length > 1 &&
    <h6> {data.Name} </h6>
  }
          <h6> {data.Description} </h6>

         <p> Ces données évolue constamment et peuvent ne pas tenir compte de certains cas dans  encore en cours de signalement.</p>
 </div> </div>
 <div align="center">
  {this.state.share &&
   <button
     class="btn btn-dark btn-round"
     onClick={() => this.share(data)}
   >        <i class="material-icons">share</i>
     Partager
   </button>
 }
 </div>
         </TabPane>
       ))}




     </Tabs>
   }

     </div>     </div>
   }
<div align="center">
      <a href="https://www.genielabel.com/" class="logo" style={{color: "#222",fontSize: 20}}>Map by Genie Label </a>
      </div>
        </div>
     }




   </div>
    );
  }
}


/*

<div     className="toggle-group absolute top left ml5 mt5 py6 px6 border border--2 border--white bg-white shadow-darken10 z2">
  {options.map(renderOptions)}
</div>

*/
export default App;
