import React, { Component } from "react";
import {

  Link,

} from "react-router-dom";
import Radio, { RadioGroup, RadioCard } from "@invisionag/iris-react-radio";
import mapboxgl from "mapbox-gl";

import NeatRTC from "neat-rtc";



class App extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      step: false,
      level: 0,
      value: 0,
      quiz: 1,
      myrespone: [],

      stepIndex: 0
    };
  }

  componentDidMount() {}

  onChange = e => {
    console.log("radio checked", e);
    this.setState({
      value: e
    });
  };


  render() {
    return (
      <header class="header absolute top right left" >

      <a href="/" class="logo" style={{color: "#222",fontSize: 20}}> Carte Covid19</a>

      <input class="menu-btn" type="checkbox" id="menu-btn" />
      <label class="menu-icon" for="menu-btn"><span class="navicon"></span></label>
      <ul class="menu">

      <li>           <Link to="/about">Plus D'informations</Link></li>
      <li> <Link to="/">Carte du coronavirus</Link></li>
      <li><Link to="/test">Teste en ligne</Link></li>
      <li><Link to="/5-Gestes-bariere">5 Gestes bariere</Link></li>

      </ul>

      </header>

    );
  }
}

export default App;
