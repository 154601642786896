import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect,
  useLocation
} from "react-router-dom";
import Map from './components/Map';
import About from './components/About';
import Video from './components/Video';
import Module from './components/Module';
import Step from './components/Step';
import './App.css';

 import Test from './components/Test';
export default function NoMatchExample() {
  return (
    <Router>
      <div>


        <Switch>
          <Route exact path="/">
            <Map />
          </Route>



          <Route exact path="/video">
            <Video />
          </Route>
          <Route path="/test">
           <Test />
          </Route>


          <Route path="/about">
           <About />
          </Route>
          <Route path="/5-Gestes-bariere">
           <Step />
          </Route>


        </Switch>
      </div>
    </Router>
  );
}
