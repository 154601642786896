import React, { Component } from 'react';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Province from "./Province.json";
import * as am4maps from "@amcharts/amcharts4/maps";

am4core.useTheme(am4themes_animated);

class App extends Component {
  componentDidMount() {
    let map   = am4core.create("chartdiv", am4maps.MapChart);




    var chart = am4core.create("chartdiv", am4maps.MapChart);

// Set map definition
chart.geodata = Province;

// Set projection
chart.projection = new am4maps.projections.Miller();

// Create map polygon series
var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());

// Make map load polygon (like country names) data from GeoJSON
polygonSeries.useGeodata = true;


  this.chart = chart;
  }

  componentWillUnmount() {
    if (this.map) {
    //  this.map.dispose();
    }
  }

  render() {
    return (
      <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
    );
  }
}

export default App;
